export const MIN_PASSWORD_STRENGTH = 2;
export const MIN_PASSWORD_LENGTH = 8;

export const MASK_IBAN = {
  mask: 'DE99 9999 9999 9999 9999 99',
  placeholder: '_',
  inputmode: 'numeric',
  outputFormat: 'DE9999999999999999',
};

export const MASK_CURRENCY = {
  prefix: '',
  groupSeparator: '.',
  radixPoint: ',',
  alias: 'numeric',
  digits: 2,
  digitsOptional: false,
  allowMinus: false,
  rightAlign: false,
  min: 0,
  max: 9999,
  germanDecimal: true,
  // unmaskAsNumber: true,
};

export const MASK_CONTAINER_SIZE_FUNC = (min = 0, max = 99, digits = 2) => ({
  suffix: ' m³',
  groupSeparator: '.',
  radixPoint: ',',
  alias: 'numeric',
  digits,
  digitsOptional: true,
  allowMinus: false,
  rightAlign: false,
  min,
  max,
  germanDecimal: true,
  // unmaskAsNumber: true,
});

export const MASK_CONTAINER_SIZE = MASK_CONTAINER_SIZE_FUNC(0, 99, 2);
