<template>
  <CContainer>
    <div class="flex justify-between items-center mb-2">
      <CSwitch
        v-model="showDeleted"
      >
        {{ $t('showDeleted') }}
      </CSwitch>
      <CButton
        variant="primary"
        size="small"
        icon-left="plus-circle"
        @click="createModal = true"
      >
        {{ $t('create') }}
      </CButton>
    </div>
    <CCard v-show="!loading" class="overflow-hidden mb-5">
      <TableList :show-create-modal.sync="createModal" :show-deleted="showDeleted" />
    </CCard>
    <div v-if="loading" class="h-[300px] flex items-center justify-center">
      <CLoadingSpinner class="w-6" />
    </div>
  </CContainer>
</template>

<script>
import TableList from '@/ui/components/Merchant/ProductContainers/TableList.vue';

export default {
  components: {
    TableList,
  },

  data: () => ({
    createModal: false,
    showDeleted: false,
  }),

  computed: {
    loading() {
      return this.$store.state.products.loading;
    },
  },
};
</script>
